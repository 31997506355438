import React from "react";
import './Comment.css'; // Import your CSS file

function Comment( {addComment , commentText , setCommentText , allComment , fullName , setFullName} ) {



    return (
        <div className="comment-container">
            <h2 className="comment-heading">Comments</h2>
            <div className="comment-form" >
                <input
                    type="text"
                    className="username-input"
                    required
                    value={fullName}
                    onChange={(e)=> setFullName(e.target.value)}
                    placeholder="Your Name"
                />
                <textarea
                    className="comment-textarea"
                    required
                    value={commentText}
                    onChange={(e)=> setCommentText(e.target.value)}
                    placeholder="Add a comment..."
                />
                <button onClick={addComment} className="comment-submit">Submit</button>
            </div>
            <ul className="comment-display-list">
                {allComment.map((item , index) => {
                 const {fullName,date, commentText} = item
                    return(
                          <>
                     <li  className="comment-display-item">
                        <div className="comment-header">
                            <span className="comment-username">{fullName}</span>
                            <span className="comment-timestamp">{date}</span>
                        </div>
                        <div className="comment-content">{commentText}</div>
                     </li>

                           </>


                    )
                })}
            </ul>
        </div>
    );
}

export default Comment;
