// import './App.css';
import Header from './components/Header.jsx'
import Home from './pages/Home.jsx'
import AboutPage from './pages/AboutPage.jsx';
import ContactPage from './pages/ContactPage.jsx';
import ProductPage from './product/ProductPage.jsx';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Overlay from './components/Overlay.jsx';
import AuthPage from './pages/AuthPage';
import BlogForm from './blogs/BlogForm.jsx'
import AdminDashboard from './admin/AdminDashboard.jsx';
import  BlogInfo from './blogs/BlogInfo.jsx';
import FloatingWhatsAppButton from './components/WhatsAppButton.jsx';

function App() {
  return (
<>

<Router>
<Header />
<FloatingWhatsAppButton/>
<Overlay/>
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/about" element={<AboutPage/>} />
      <Route path="/contact" element={<ContactPage/>} />
      <Route path="/Product" element={<ProductPage/>} />
      <Route path="/admin" element={<AuthPage />} />
      <Route path="/blogform" element={<BlogForm/>} />
      <Route path="/admindashboard" element={<AdminDashboard />} />
      <Route path="/bloginfo/:id" element={<BlogInfo/>} />
    </Routes>
  </Router>

</>
  );
}

export default App;
