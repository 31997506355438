// src/AboutSection.js
import React from 'react';
import './AboutSection.css';

const AboutSection = () => {
return(
  <>
  <div class="container">
  <div class="contentLeft">
    <div class="row">
        <div class="imgWrapper">
            <img src="https://images.unsplash.com/photo-1687579521048-217e24217d53?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2ODg5ODcxNzl8&ixlib=rb-4.0.3&q=85" alt=""/>
        </div>
        <div class="imgWrapper">
            <img src="https://images.unsplash.com/photo-1686580546412-80e80519abd7?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2ODg5ODcyMDN8&ixlib=rb-4.0.3&q=85" alt=""/>
        </div>
        <div class="imgWrapper">
            <img src="https://images.unsplash.com/photo-1688133338995-a394ce7314e4?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2ODg5ODcyMDN8&ixlib=rb-4.0.3&q=85" alt=""/>
        </div>
        <div class="imgWrapper">
            <img src="https://images.unsplash.com/photo-1686354715732-7e4685269a25?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2ODg5ODcyNzN8&ixlib=rb-4.0.3&q=85" alt=""/>
        </div>
    </div>
  </div>
  <div class="contentRight">
    <div class="content">
      <h2>About Us </h2>
      <p>Welcome to Giftoworld! At Giftoworld, we’re dedicated to making every occasion special with our carefully curated selection of unique and thoughtful gifts. Our mission is to help you find the perfect present for your loved ones or a special treat for yourself, bringing joy and warmth to life's memorable moments.
<br /> <br />
With a commitment to quality and exceptional customer service, Giftoworld strives to be your ultimate destination for all things gifting. From personalized keepsakes to elegant gift sets, we ensure that each item in our collection meets our high standards of excellence.
<br /> <br />
Discover why Giftoworld is more than just a store – it’s a celebration of meaningful connections and cherished moments.</p>
      <a href="#">Read More...</a>
    </div>
  </div>
</div>
  </>
)
};

export default AboutSection;
