import React from "react";
import "./HorizontalScrollCards.css";
import giftbox from '../img/Giftbox.jpeg'
import Humidifier from '../img/Humidifier.jpeg'
import speker from '../img/speker.jpeg'
import Clock from '../img/Clock.jpeg'
import Sticker from '../img/Sticker.jpeg'
import Cartões from '../img/Cartões.jpeg'

const HorizontalScrollCards = () => {
  // Define the card content directly in the component
  const cardContent = [
    { title: "Gift Box", description: "All White Gift Box", imageUrl: giftbox },
    { title: "Humidifier", description: "Air Purifier And Humidifier", imageUrl: Humidifier },
    { title: "Gf2315 Wireless", description: "Speaker and Charging", imageUrl: speker },
    { title: "Alarm Clock", description: "Classic Retro", imageUrl: Clock },
    { title: "Sticker", description: "Stickers and More", imageUrl: Sticker },
    { title: "Porta Cartões", description: "Personalizados Memory Brindes", imageUrl: Cartões }
  ];

  return (
    <div className="horizontal-scroll-wrapper">
      <div className="horizontal-scroll-container">
        {cardContent.map((card, index) => (
          <div key={index} className="scroll-card">
            <img 
              src={card.imageUrl} 
              alt={card.title} 
              className="img-auto" 
            />
            <div >
              <h2 className="card-title">{card.title}</h2>
              <p className="card-description">{card.description}</p>
            </div>
          </div>
        ))}
        {/* Duplicate the content to create a seamless loop */}
        {cardContent.map((card, index) => (
          <div key={`duplicate-${index}`} className="scroll-card">
            <img 
              src={card.imageUrl} 
              alt={card.title} 
              className="img-auto" 
            />
            <div >
              <h2 className="card-title">{card.title}</h2>
              <p className="card-description">{card.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalScrollCards;
