import React, { useState } from 'react';
import './AuthPage.css';
import { getAuth  , createUserWithEmailAndPassword , signInWithEmailAndPassword  } from "firebase/auth"
import {app} from '../firebase' 
const auth = getAuth(app)




const AuthPage = () => {
  const [isLogin, setIsLogin] = useState(true);

  const handleToggle = () => {
    setIsLogin(!isLogin);
  };

  return (
    <div className="auth-container">
      {isLogin ? (
        <LoginForm onToggle={handleToggle} />
      ) : (
        <SignupForm onToggle={handleToggle} />
      )}
    </div>
  );
};

const LoginForm = ({ onToggle }) => {

    const [isemail, issetemail] = useState("");
    const [ispassword, issetpassword] = useState("");
  
      const login = async  (e)  =>{
          
        e.preventDefault(); // Prevent default form submission
  
      try {
        await signInWithEmailAndPassword (auth, isemail, ispassword);
        alert('Login Success');
      } catch (error) {
        alert('Error: ' + error.message);
      }

      }


//demo@mail.com
//demo123
      

  return (
    <div className="form-container login-form">
      <h2>Login</h2>
      <form  onSubmit={login}>
        <div>
          <label htmlFor="login-email">Email:</label>
          <input 
          type="email" 
          id="login-email" 
          name="email"    
          required
          placeholder='ENTER YOUR EMAIL'
          value={isemail}
          onChange={e => issetemail(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="login-password">Password:</label>
          <input 
          type="password" 
          id="login-password" 
          name="password"    
          required
          placeholder='ENTER YOUR PASSWORD'
          value={ispassword}
          onChange={e => issetpassword(e.target.value)}
           />
        </div>
        <button type="submit">Login</button>
      </form>
      <p>Don't have an account? <button onClick={onToggle}>Create one</button></p>
    </div>
  );
};

const SignupForm = ({ onToggle }) => {
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
  
    const createuser = async (e) => {
      e.preventDefault(); // Prevent default form submission
  
      try {
        await createUserWithEmailAndPassword(auth, email, password);
        alert('Success');
      } catch (error) {
        alert('Error: ' + error.message);
      }
    };
  
    return (
      <div className="form-container signup-form">
        <h2>Sign Up</h2>
        <form onSubmit={createuser}>
          <div>
            <label htmlFor="signup-email">Email:</label>
            <input
              onChange={e => setemail(e.target.value)}
              value={email}
              type="email"
              id="signup-email"
              name="email"
              required
              placeholder='ENTER YOUR EMAIL'
            />
          </div>
          <div>
            <label htmlFor="signup-password">Password:</label>
            <input
              onChange={e => setpassword(e.target.value)}
              value={password}
              type="password"
              id="signup-password"
              name="password"
              required
              placeholder='ENTER YOUR PASSWORD'
            />
          </div>
          <button type="submit">Sign Up</button>
        </form>
        <p>Already have an account? <button onClick={onToggle}>Log in</button></p>
      </div>
    );
  };
  

export default AuthPage;
