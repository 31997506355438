// src/components/Overlay.js
import React from 'react';

const overlayStyle = {
  position: 'fixed',
  top: '120px', // Position from the top

  backgroundColor: 'rgba(0, 0, 0, 0.9)', // Darker background for contrast
  color: '#fff',
  padding: '10px 20px', // Increased padding for a bigger banner
  borderRadius: '8px', // Slightly rounded corners

  zIndex: 1000, // Ensure it stays on top
  display: 'flex',
  alignItems: 'center',
  transform: 'rotate(-30deg)', // Rotate the banner
  transformOrigin: 'top left', // Set rotation origin
};

const overlayContentStyle = {
  fontSize: '14px', // Slightly larger font size
  whiteSpace: 'nowrap', // Prevent text wrapping
};

const Overlay = () => {
  return (
    <div style={overlayStyle}>
      <div style={overlayContentStyle}>
        🚧 Site Under Development 🚧
      </div>
    </div>
  );
};

export default Overlay;
