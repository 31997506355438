import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
// import { getAuth } from "firebase/auth";
import { getStorage  } from "firebase/storage"


const firebaseConfig = {
    apiKey: "AIzaSyDJBj8QrymhpPz0uc8zs_bz38RxyhOQHGc",
    authDomain: "giftworld-cc4ca.firebaseapp.com",
    projectId: "giftworld-cc4ca",
    storageBucket: "giftworld-cc4ca.appspot.com",
    messagingSenderId: "569355817468",
    appId: "1:569355817468:web:67ba1c10c4faf85e776279",
    measurementId: "G-7JQCQGL1F8"
  };

  const app = initializeApp(firebaseConfig);
   const fireDb =   getFirestore(app);
   const storage = getStorage(app);

   export { app , fireDb  , storage }