import React, { useState } from "react";

function FloatingWhatsAppButton() {
    const [showTooltip, setShowTooltip] = useState(false);
    const phoneNumber = "8454917579"; // Replace with your phone number

    const buttonStyle = {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000,

    };

    const imageStyle = {
        width: '70px',
        height: '70px',
        cursor: 'pointer',

    };

    const tooltipStyle = {
        visibility: showTooltip ? 'visible' : 'hidden',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        color: '#fff',
        borderRadius: '5px',
        padding: '5px 10px',
        textAlign: 'center',
        position: 'absolute',
        bottom: '80px',
        right: '0',
        whiteSpace: 'nowrap',
        zIndex: 1001,
    };

    const handleHover = () => {
        setShowTooltip(true);
        document.querySelector('img').style.transform = 'scale(1.1)';
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
        document.querySelector('img').style.transform = 'scale(1)';
    };

    return (
        <div style={buttonStyle}>
            <a
                href={`https://wa.me/${phoneNumber}`}
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
            >
                <img
                    src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png"
                    alt="WhatsApp"
                    style={imageStyle}
                />
            </a>
            <div style={tooltipStyle}>
                Contact us for bulk order
            </div>
        </div>
    );
}

export default FloatingWhatsAppButton;
