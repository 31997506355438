import React from 'react';
import './WelcomeSection.css';

const WelcomeSection = () => {
  return (
    <section className="section-welcome">
      <div className="welcome">
        <div className="welcome-text-box">
          <h1 className="heading-primary">
            Perfect gifts for every occasion, crafted with care  
          </h1>
          <p className="welcome-description">
          Discover the joy of giving with our carefully curated collection of gifts, each selected to bring a smile and make every occasion unforgettable.
          </p>
          <a href="#" className="btn btn--fill margin-right-btn">
            Start Gifting
          </a>
          <a href="#" className="btn btn--outline margin-right-btn">
            Learn more &darr;
          </a>
        </div>
        <div className="welcome-img-box">
          <img
            src="https://prayagtandon.github.io/Omnifood-Project/Hero-section/img/hero.png"
            alt="Woman enjoying food, meals in storage container, and food bowls on a table"
            className="welcome-img"
          />
        </div>
        <div className="delivered-meals">
          <div className="delivered-imgs">
            <img src="https://prayagtandon.github.io/Omnifood-Project/Hero-section/img/customers/customer-1.jpg" alt="Customer photo" />
            <img src="https://prayagtandon.github.io/Omnifood-Project/Hero-section/img/customers/customer-2.jpg" alt="Customer photo" />
            <img src="https://prayagtandon.github.io/Omnifood-Project/Hero-section/img/customers/customer-3.jpg" alt="Customer photo" />
            <img src="https://prayagtandon.github.io/Omnifood-Project/Hero-section/img/customers/customer-4.jpg" alt="Customer photo" />
            <img src="https://prayagtandon.github.io/Omnifood-Project/Hero-section/img/customers/customer-5.jpg" alt="Customer photo" />
            <img src="https://prayagtandon.github.io/Omnifood-Project/Hero-section/img/customers/customer-6.jpg" alt="Customer photo" />
          </div>
          <p className="delivered-text">
            <span>250,000+</span> gifts delivered last year!
          </p>
        </div>
      </div>
    </section>
  );
};

export default WelcomeSection;
