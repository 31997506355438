
import { FaBars, FaTimes } from 'react-icons/fa';
import './Header.css'; // Import the CSS file for styling
import { useState } from 'react';
import logo from '../img/giftoworld-Logo-3.png';
import { Link } from 'react-router-dom';
import pdfFile from '../img/giftworld.pdf'; 

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };


  return (
    <nav className="navbar">

  <Link to='/' ><img src={logo} alt="" className="logo" /></Link> 

      <button className="menu-toggle" onClick={toggleMenu}>
        {menuOpen ? <FaTimes /> : <FaBars />}
      </button>

      <ul className={`nav-list ${menuOpen ? 'active' : ''}`}>

  <li className="nav-item">
         <Link  to='/' className="nav-link" > Home </Link>
        </li>


        <li className="nav-item  services dropdown">


        <Link  to='/Product' className="nav-link"    >  All Product</Link>  

<ul className="dropdown-menu">
  <li>
    <Link to={`/product?category=giftsets`}>
      Gift Sets
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Office Diaries`}>
      Office Diaries
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Hot and Cold Flask`}>
      Hot and Cold Flask
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Steel Water Bottles`}>
      Steel Water Bottles
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Aluminium Water Bottles`}>
      Aluminium Water Bottles
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Plastic Water Bottles`}>
      Plastic Water Bottles
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Mugs and Kettles`}>
      Mugs and Kettles
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Travel Bags and Pouches`}>
      Travel Bags and Pouches
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Passport Holders`}>
      Passport Holders
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Eco Products and Sticky Notepads`}>
      Eco Products and Sticky Notepads
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Office Conference Folders`}>
      Office Conference Folders
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Crystal Trophies and Awards`}>
      Crystal Trophies and Awards
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Metal Pens`}>
      Metal Pens
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Photo Frame`}>
      Photo Frame
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Tiffin`}>
      Tiffin
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Plastic Pens`}>
      Plastic Pens
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Multiple Pens`}>
      Multiple Pens
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Metal Keychains`}>
      Metal Keychains
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Business Card Holders`}>
      Business Card Holders
    </Link>
  </li>

  <li>
    <Link to={`/product?category=USB Pendrives`}>
      USB Pendrives
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Mobile Accessories`}>
      Mobile Accessories
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Wireless Mouse and Keyboards`}>
      Wireless Mouse and Keyboards
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Desktop Life Time Calendars`}>
      Desktop Life Time Calendars
    </Link>
  </li>

  <li>
    <Link to={`/product?category=Mix Products`}>
      Mix Products
    </Link>
  </li>

</ul>
         
        </li>
        <li className="nav-item dropdown">
       <Link to={`/product?category=Office Diaries`} className="nav-link" >  Diaries</Link> 
          <ul className="dropdown-menu">
        <Link  to={`/product?category=Office Diaries`}  >    <li><a   >Office Diaries</a></li> </Link>
        <Link  to={`/product?category=Eco Products and Sticky Notepads`}  >    <li><a   >Eco Products and Sticky Notepads</a></li> </Link>
        <Link  to={`/product?category=Office Conference Folders`}  >    <li><a   >Office Conference Folders</a></li> </Link>
          </ul>
        </li>


        <li className="nav-item dropdown">
       <Link  to={`/product?category=Steel Water Bottles`} className="nav-link"  > Water Bottles</Link> 
          <ul className="dropdown-menu">
         <Link to={`/product?category=Steel Water Bottles`} >  <li><a   >Steel Water Bottles</a></li></Link>
         <Link to={`/product?category=Hot and Cold Flask`} >  <li><a   >Hot and Cold Flask</a></li></Link>
         <Link to={`/product?category=Aluminium Water Bottles`} >  <li><a >Aluminium Water Bottles</a></li></Link>
         <Link to={`/product?category=Plastic Water Bottles`} >  <li><a   >Plastic Water Bottles</a></li></Link>
         <Link to={`/product?category=Mugs and Kettles`} >  <li><a   >Mugs and Kettles</a></li></Link>
          </ul>
        </li>


        <li className="nav-item dropdown">
       <Link  to={`/product?category=Travel Bags and Pouches`} className="nav-link"  >Bags</Link> 
          <ul className="dropdown-menu">
          <Link to={`/product?category=Travel Bags and Pouches`} >  <li><a   >Travel Bags and Pouches</a></li></Link>
          <Link to={`/product?category=Passport Holders`} >  <li><a   >Passport Holders</a></li></Link>
          </ul>
        </li>


        <li className="nav-item dropdown">

       <Link  to={`/product?category=Metal Pens`} className="nav-link" > Pens </Link> 
          <ul className="dropdown-menu">
            <Link to={`/product?category=Metal Pens`} >  <li><a>Metal Pens</a></li></Link>
            <Link to={`/product?category=Plastic Pens`} >  <li><a>Plastic Pens</a></li></Link>
            <Link to={`/product?category=Multiple Pens`} >  <li><a>Multiple Pens</a></li></Link>
          </ul>
        </li>


        <li className="nav-item dropdown">
  
       <Link  to={`/product?category=Mobile Accessories`} className="nav-link" >Electronics</Link> 
          <ul className="dropdown-menu">

            <Link to={`/product?category=Mobile Accessories`} >  <li><a>Mobile Accessories</a></li></Link>

            <Link to={`/product?category=USB Pendrives`} >  <li><a>USB Pendrives</a></li></Link>

            <Link to={`/product?category=Wireless Mouse and Keyboards`} >  <li><a>Wireless Mouse and Keyboards</a></li></Link>

            <Link to={`/product?category=Laptop Stand`} >  <li><a>Laptop Stand</a></li></Link>
          </ul>
        </li>



        <li className="nav-item dropdown">
       <Link  to='/about' >  <a  className="nav-link">About</a> </Link> 
 
        </li>



        <li className="nav-item dropdown">
        <Link to="/contact" > <a  className="nav-link">Contact</a> </Link>
     
        </li>



        <a href={pdfFile} download="giftworld.pdf">
      <button className="button-btn">
      Download Catalog PDF
      </button>
    </a>


      </ul>
    </nav>
  );
};

export default Header;









