import React from 'react';
import './HeroSection.css'

const HeroSection = () => {
  return (
    <div className="hero-section">
  
    </div>
  );
};

export default HeroSection;
