
// imp until the realtive product not work

// import React, { useEffect, useState } from "react";
// import './Bloginfo.css';
// import { useParams } from "react-router-dom";
// import { addDoc, collection, doc, getDoc, onSnapshot, orderBy, query, Timestamp } from "firebase/firestore";
// import { fireDb } from "../firebase";
// import Comment from '../components/Comments/Comment.jsx';

// import 'react-medium-image-zoom/dist/styles.css'; // Import the default styles

// function Bloginfo() {
//     const params = useParams();
//     const [getBlogs, setGetBlogs] = useState();
//     const [loading, setLoading] = useState();
//     const [fullName, setFullName] = useState('');
//     const [commentText, setCommentText] = useState('');
//     const [allComment, setAllComment] = useState([]);

//     const getAllBlogs = async () => {
//         setLoading(true);
//         try {
//             const blogDoc = await getDoc(doc(fireDb, "blogPost", params.id));
//             if (blogDoc.exists()) {
//                 setGetBlogs(blogDoc.data());
//             } else {
//                 console.log("Document does not exist");
//             }
//             setLoading(false);
//         } catch (error) {
//             console.log(error);
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         getAllBlogs();
//         window.scrollTo(0, 0);
//     }, []);




// console.log(getBlogs);




//     const addComment = async () => {
//         if (fullName.trim() === '' || commentText.trim() === '') {
//             alert('Please fill out both your name and comment before submitting.');
//             return;
//         }

//         const commentRef = collection(fireDb, "blogPost/" + `${params.id}/` + "comment");
//         try {
//             await addDoc(commentRef, {
//                 fullName,
//                 commentText,
//                 time: Timestamp.now(),
//                 date: new Date().toLocaleString(
//                     "en-US",
//                     {
//                         month: "short",
//                         day: "2-digit",
//                         year: "numeric",
//                     }
//                 )
//             });
//             alert('Comment Added Successfully');
//             setFullName("");
//             setCommentText("");
//         } catch (error) {
//             console.log(error);
//         }
//     };

//     const getComment = async () => {
//         try {
//             const q = query(
//                 collection(fireDb, "blogPost/" + `${params.id}/` + "comment/"),
//                 orderBy('time')
//             );
//             const data = onSnapshot(q, (QuerySnapshot) => {
//                 let productsArray = [];
//                 QuerySnapshot.forEach((doc) => {
//                     productsArray.push({ ...doc.data(), id: doc.id });
//                 });
//                 setAllComment(productsArray);
//                 console.log(productsArray);
//             });
//             return () => data();
//         } catch (error) {
//             console.log(error);
//         }
//     };

//     useEffect(() => {
//         getComment();
//     }, []);

//     const handleWhatsAppClick = () => {
//         const phoneNumber = '8454917579'; /*8976482969 */
//         const currentUrl = window.location.href;
//         const message = `Hi! Can you give me more details about this product?: ${currentUrl}`;
//         const encodedMessage = encodeURIComponent(message);
//         const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
//         window.open(whatsappURL, '_blank');
//     };
    
//     return (
//         <>
//             <div className="page-background">
//                 <div className="content-container">
//                     {loading ? (
//                         <div className="loading-spinner"></div>
//                     ) : (
//                         <>
//                             <div className="image-wrapper">
    
//                                     <img 
//                                         src={getBlogs?.thumbnail} 
//                                         alt={getBlogs?.blogs?.title} 
//                                         className="main-image" 
//                                     />
                   
//                             </div>
//                             <div className="details-wrapper">
//                                 <h1 className="fixinfodata">Product details</h1>
//                                 <hr />
//                                 <h1 className="text">{getBlogs?.blogs?.title}</h1>
//                                 <h4 className="fixinfodata">Description About this product:-</h4>
//                                 <p className="text">{getBlogs?.blogs?.content}</p>
//                                 <h4 className="fixinfodata">Date when the Product was Added</h4>
//                                 <p className="text">{getBlogs?.date}</p>
//                                 <h4 className="fixinfodata">WhatsApp us for bulk order and more details</h4>
//                                 <button className="contact-button tooltip" onClick={handleWhatsAppClick}>
//                                     <i className="fab fa-whatsapp"></i>
//                                     WhatsApp
//                                 </button>
//                             </div>
//                         </>
//                     )}
//                 </div>
//             </div>

//             <Comment
//                 addComment={addComment}
//                 commentText={commentText}
//                 setCommentText={setCommentText}
//                 allComment={allComment}
//                 fullName={fullName}
//                 setFullName={setFullName}
//             />
//         </>
//     );
// }

// export default Bloginfo;


// import React, { useContext, useEffect, useState } from "react";
// import './Bloginfo.css';
// import { useParams } from "react-router-dom";
// import { addDoc, collection, doc, getDoc, onSnapshot, orderBy, query, Timestamp } from "firebase/firestore";
// import { fireDb } from "../firebase";
// import Comment from '../components/Comments/Comment.jsx';
// import DataContext from '../Context/DataContext';
// import 'react-medium-image-zoom/dist/styles.css'; 
// import { useNavigate } from 'react-router-dom';

// function Bloginfo() {
//     const params = useParams();
//     const [getBlogs, setGetBlogs] = useState();
//     const [loading, setLoading] = useState();
//     const [fullName, setFullName] = useState('');
//     const [commentText, setCommentText] = useState('');
//     const [allComment, setAllComment] = useState([]);
//     const { getAllBlog } = useContext(DataContext); 
    
//     const navigate = useNavigate();

//     const getAllBlogs = async () => {
//         setLoading(true);
//         try {
//             const blogDoc = await getDoc(doc(fireDb, "blogPost", params.id));
//             if (blogDoc.exists()) {
//                 setGetBlogs(blogDoc.data());
//             } else {
//                 console.log("Document does not exist");
//             }
//             setLoading(false);
//         } catch (error) {
//             console.log(error);
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         getAllBlogs();
//         window.scrollTo(0, 0);
//     }, [params.id]);

//     const addComment = async () => {
//         if (fullName.trim() === '' || commentText.trim() === '') {
//             alert('Please fill out both your name and comment before submitting.');
//             return;
//         }

//         const commentRef = collection(fireDb, "blogPost/" + `${params.id}/` + "comment");
//         try {
//             await addDoc(commentRef, {
//                 fullName,
//                 commentText,
//                 time: Timestamp.now(),
//                 date: new Date().toLocaleString(
//                     "en-US",
//                     {
//                         month: "short",
//                         day: "2-digit",
//                         year: "numeric",
//                     }
//                 )
//             });
//             alert('Comment Added Successfully');
//             setFullName("");
//             setCommentText("");
//         } catch (error) {
//             console.log(error);
//         }
//     };

//     const getComment = async () => {
//         try {
//             const q = query(
//                 collection(fireDb, "blogPost/" + `${params.id}/` + "comment/"),
//                 orderBy('time')
//             );
//             const data = onSnapshot(q, (QuerySnapshot) => {
//                 let productsArray = [];
//                 QuerySnapshot.forEach((doc) => {
//                     productsArray.push({ ...doc.data(), id: doc.id });
//                 });
//                 setAllComment(productsArray);
//                 console.log(productsArray);
//             });
//             return () => data();
//         } catch (error) {
//             console.log(error);
//         }
//     };

//     useEffect(() => {
//         getComment();
//     }, [params.id]);

//     const handleWhatsAppClick = () => {
//         const phoneNumber = '8454917579'; /*8976482969 */
//         const currentUrl = window.location.href;
//         const message = `Hi! Can you give me more details about this product?: ${currentUrl}`;
//         const encodedMessage = encodeURIComponent(message);
//         const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
//         window.open(whatsappURL, '_blank');
//     };

//     // Filter related products based on the category of the current blog
//     const relatedProducts = getAllBlog?.filter(
//         (product) => product?.blogs?.category === getBlogs?.blogs?.category && product.id !== params.id
//     );

//     return (
//         <>
//             <div className="page-background">
//                 <div className="content-container">
//                     {loading ? (
//                         <div className="loading-spinner"></div>
//                     ) : (
//                         <>
//                             <div className="image-wrapper">
//                                 <img 
//                                     src={getBlogs?.thumbnail} 
//                                     alt={getBlogs?.blogs?.title} 
//                                     className="main-image" 
//                                 />
//                             </div>
//                             <div className="details-wrapper">
//                                 <h1 className="fixinfodata">Product details</h1>
//                                 <hr />
//                                 <h1 className="text">{getBlogs?.blogs?.title}</h1>
//                                 <h4 className="fixinfodata">Description About this product:-</h4>
//                                 <p className="text">{getBlogs?.blogs?.content}</p>
//                                 <h4 className="fixinfodata">Date when the Product was Added</h4>
//                                 <p className="text">{getBlogs?.date}</p>
//                                 <h4 className="fixinfodata">WhatsApp us for bulk order and more details</h4>
//                                 <button className="contact-button tooltip" onClick={handleWhatsAppClick}>
//                                     <i className="fab fa-whatsapp"></i>
//                                     WhatsApp
//                                 </button>
//                             </div>
//                         </>
//                     )}
//                 </div>
//             </div>

     
//             {/* Display Related Products */}
//             <div className="related-products-section">

                
//                 <h2 id="rp" >Related Products</h2>
//                 <hr />
//                 <div className="related-products-container">
//                     {relatedProducts?.length > 0 ? (
//                         relatedProducts.map((product) => (
//                             <div key={product.id} className="related-product-card">
//                                 <img  onClick={ () =>  navigate(`/bloginfo/${product.id}`) } src={product.thumbnail} alt={product?.blogs?.title}    />
//                                 <h3>{product?.blogs?.title}</h3>
//                                 <p>{product?.blogs?.content}</p>
//                                 <p>{product?.date}</p>
                        
//                             </div>
//                         ))
//                     ) : (
//                         <p>No related products found</p>
//                     )}
//                 </div>
//             </div>



//        {/* Comments Section */}
//             <Comment
//                 addComment={addComment}
//                 commentText={commentText}
//                 setCommentText={setCommentText}
//                 allComment={allComment}
//                 fullName={fullName}
//                 setFullName={setFullName}
//             />






//         </>
//     );
// }

// export default Bloginfo;




















import React, { useContext, useEffect, useState } from "react";
import './Bloginfo.css';
import { useParams } from "react-router-dom";
import { addDoc, collection, doc, getDoc, onSnapshot, orderBy, query, Timestamp } from "firebase/firestore";
import { fireDb } from "../firebase";
import Comment from '../components/Comments/Comment.jsx';
import DataContext from '../Context/DataContext';
import 'react-medium-image-zoom/dist/styles.css'; 
import { useNavigate } from 'react-router-dom';

function Bloginfo() {
    const params = useParams();
    const [getBlogs, setGetBlogs] = useState();
    const [loading, setLoading] = useState();
    const [fullName, setFullName] = useState('');
    const [commentText, setCommentText] = useState('');
    const [allComment, setAllComment] = useState([]);
    const { getAllBlog } = useContext(DataContext); 
    
    const navigate = useNavigate();

    const getAllBlogs = async () => {
        setLoading(true);
        try {
            const blogDoc = await getDoc(doc(fireDb, "blogPost", params.id));
            if (blogDoc.exists()) {
                setGetBlogs(blogDoc.data());
            } else {
                console.log("Document does not exist");
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllBlogs();
        window.scrollTo(0, 0);
    }, [params.id]);

    const addComment = async () => {
        if (fullName.trim() === '' || commentText.trim() === '') {
            alert('Please fill out both your name and comment before submitting.');
            return;
        }

        const commentRef = collection(fireDb, "blogPost/" + `${params.id}/` + "comment");
        try {
            await addDoc(commentRef, {
                fullName,
                commentText,
                time: Timestamp.now(),
                date: new Date().toLocaleString(
                    "en-US",
                    {
                        month: "short",
                        day: "2-digit",
                        year: "numeric",
                    }
                )
            });
            alert('Comment Added Successfully');
            setFullName("");
            setCommentText("");
        } catch (error) {
            console.log(error);
        }
    };

    const getComment = async () => {
        try {
            const q = query(
                collection(fireDb, "blogPost/" + `${params.id}/` + "comment/"),
                orderBy('time')
            );
            const data = onSnapshot(q, (QuerySnapshot) => {
                let productsArray = [];
                QuerySnapshot.forEach((doc) => {
                    productsArray.push({ ...doc.data(), id: doc.id });
                });
                setAllComment(productsArray);
                console.log(productsArray);
            });
            return () => data();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getComment();
    }, [params.id]);

    const handleWhatsAppClick = () => {
        const phoneNumber = '8454917579'; /*8976482969 */
        const currentUrl = window.location.href;
        const message = `Hi! Can you give me more details about this product?: ${currentUrl}`;
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
        window.open(whatsappURL, '_blank');
    };

    // Filter related products based on the category of the current blog
    const relatedProducts = getAllBlog?.filter(
        (product) => product?.blogs?.category === getBlogs?.blogs?.category && product.id !== params.id
    );

    // Limit to 6 products
    const displayedRelatedProducts = relatedProducts?.slice(0, 5);

    return (
        <>
            <div className="page-background">
                <div className="content-container">
                    {loading ? (
                        <div className="loading-spinner"></div>
                    ) : (
                        <>
                            <div className="image-wrapper">
                                <img 
                                    src={getBlogs?.thumbnail} 
                                    alt={getBlogs?.blogs?.title} 
                                    className="main-image" 
                                />
                            </div>
                            <div className="details-wrapper">
                                <h1 className="fixinfodata">Product details</h1>
                                <hr />
                                <h1 className="text">{getBlogs?.blogs?.title}</h1>
                                <h4 className="fixinfodata">Description About this product:-</h4>
                                <p className="text">{getBlogs?.blogs?.content}</p>
                                <h4 className="fixinfodata">Date when the Product was Added</h4>
                                <p className="text">{getBlogs?.date}</p>
                                <h4 className="fixinfodata">WhatsApp us for bulk order and more details</h4>
                                <button className="contact-button tooltip" onClick={handleWhatsAppClick}>
                                    <i className="fab fa-whatsapp"></i>
                                    WhatsApp
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* Display Related Products */}
            <div className="related-products-section">
                <h2 id="rp">Related Products</h2>
                <hr />
                <div className="related-products-container">
                    {displayedRelatedProducts?.length > 0 ? (
                        displayedRelatedProducts.map((product) => (
                            <div key={product.id} className="related-product-card">
                                <img onClick={() => navigate(`/bloginfo/${product.id}`)} src={product.thumbnail} alt={product?.blogs?.title} />
                                <h3>{product?.blogs?.title}</h3>
                                <p>{product?.blogs?.content}</p>
                                <p>{product?.date}</p>
                            </div>
                        ))
                    ) : (
                        <p>No related products found</p>
                    )}
                </div>
            </div>

            {/* Comments Section */}
            <Comment
                addComment={addComment}
                commentText={commentText}
                setCommentText={setCommentText}
                allComment={allComment}
                fullName={fullName}
                setFullName={setFullName}
            />
        </>
    );
}

export default Bloginfo;
