import React from 'react';
import './AdminDashboard.css'; 
import { Link } from 'react-router-dom';

const Sidebar = () => (
  <div className="sidebar">
    <h2>Admin Panel</h2>
    <ul>
      <li>Dashboard</li>
     <Link   to='/blogform' ><li>Add product</li> </Link> 
      <li>Posts</li>
     {/*  <li>Comments</li> */}
    
    </ul>
  </div>
);

const DashboardContent = () => (
  <div className="dashboard-content">
    <h1>Welcome to the Admin Dashboard</h1>
    <div className="stats">
      <div className="stat-box">DATA</div>
      <div className="stat-box">DATA</div>
      <div className="stat-box">DATA</div>
    </div>
  </div>
);

const AdminDashboard = () => (
  <div className="admin-dashboard">
    <Sidebar />
    <div className="main-content">
      <DashboardContent />
    </div>
  </div>
);

export default AdminDashboard;
