import React, { useState, useEffect } from 'react';
import './Slider.css'; 
import glassTumblerSlide from '../img/slide-new.jpg';
import greenSlide from '../img/green-slide.jpg';
import mugslide from '../img/mug-slide.jpg';

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    {
      url: glassTumblerSlide,
      description: "Glass tumbler with glass straw and airtight wooden lid!"
    },
    {
      url: greenSlide,
      description: "Green gifting solutions!"
    },
    {
      url: mugslide,
      description: "Sublimation transparent mug!"
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000); // Auto-slide every 5 seconds
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [currentIndex]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="slider-container">
      <div className="slide">
        <img src={slides[currentIndex].url} alt={`Gift ${currentIndex + 1}`} />
        <div className="slide-description">
          {slides[currentIndex].description}
        </div>
      </div>
      <button className="prev-slide-btn" onClick={handlePrev}>‹</button>
      <button className="next-slide-btn" onClick={handleNext}>›</button>
    </div>
  );
};

export default Slider;
