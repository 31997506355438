// import React, { useContext, useEffect, useState } from 'react';
// import './BlogForm.css'; // Import the CSS file
// import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
// import { addDoc, collection, Timestamp } from 'firebase/firestore';
// import { fireDb, storage } from '../firebase';
// import { toast } from 'react-toastify';
// import DataContext from '../Context/DataContext';



// const BlogForm = () => {

//   const [blogs, setBlogs] = useState({
//     title: '',
//     category: '',
//     content: '',
//     time: Timestamp.now(),
//   });

//   //   const [image, setImage] = useState(null);
//   const [thumbnail, setthumbnail] = useState();
//   const [imagePreview, setImagePreview] = useState(null);

//   // table code 
//   // const [getAllBlog, setGetAllBlog] = useState([]);
//   const { getAllBlog  , deleteBlogs  } = useContext(DataContext);


// //form
//   const addPost = async (e) => {
//     e.preventDefault();
//     if (blogs.title === "" || blogs.category === "" || blogs.content === "" || blogs.thumbnail === "") {
//       toast.error('Please Fill All Fields');
//     }
//     // console.log(blogs.content)
//     uploadImage()

//     setBlogs({
//       title: '',
//       category: '',
//       content: '',
//       time: Timestamp.now(), // Reset time if needed
//     });

//     // Reset the thumbnail and image preview
//     setthumbnail(null);
//     setImagePreview(null);

//     // You may also reset the file input element if necessary
//     e.target.reset();

    
//   }

//   const uploadImage = () => {
//     if (!thumbnail) return;
//     const imageRef = ref(storage, `blogimage/${thumbnail.name}`);
//     uploadBytes(imageRef, thumbnail).then((snapshot) => {
//       getDownloadURL(snapshot.ref).then((url) => {
//         const productRef = collection(fireDb, "blogPost")
//         try {
//           addDoc(productRef, {
//             blogs,
//             thumbnail: url,
//             time: Timestamp.now(),
//             date: new Date().toLocaleString(
//               "en-US",
//               {
//                 month: "short",
//                 day: "2-digit",
//                 year: "numeric",
//               }
//             )
//           })
//           alert('Data Added Successfully');



//         } catch (error) {
//           toast.error(error)
//           console.log(error)
//         }
//       });
//     });
//   }


//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     setthumbnail(file);
//     setImagePreview(URL.createObjectURL(file));
//   };


//   return (

//     <>


//       <form onSubmit={addPost} className="blog-form">
//         <div className="form-group">
//           <label>Title:</label>
//           <input
//             type="text"
//             onChange={(e) => setBlogs({ ...blogs, title: e.target.value })}
//             value={blogs.title}
//             required
//             className='inputtag'
//           />
//         </div>

//         <div className="form-group">
//           <label>Category:</label>
//           <input
//             type="text"
//             onChange={(e) => setBlogs({ ...blogs, category: e.target.value })}
//             value={blogs.category}
//             required
//                       className='inputtag'
//           />
//         </div>

//         <div className="form-group">
//           <label>Description:</label>
//           <textarea
//             onChange={(e) => setBlogs({ ...blogs, content: e.target.value })}
//             value={blogs.contenty}
//             rows="4"
//             required
//           ></textarea>
//         </div>

//         <div className="form-group">
//           <label>Image:</label>
//           <input
//             type="file"
//             onChange={handleImageChange}
//             required
//                       className='inputtag'
//           />
//         </div>

//         {imagePreview && (
//           <div className="image-preview">
//             <img src={imagePreview} alt="Selected" />
//           </div>
//         )}

//         <button type="submit" className="submit-button">
//           Submit
//         </button>
//       </form>

//       {/* table */}

//       <div className="posts-page">
//         <div className="header">
//           <h1>Posts</h1>
//           <div className="header-actions">
//             {/* <button className="add-post-btn" onClick={handleAddPost}>Add New Post</button> */}
//           </div>
//         </div>
//         <div className="table-container">
//           <table className="posts-table">
//             <thead>
//               <tr>
//                 <th>Sr. No.</th>
//                 <th>Thumbnail</th>
//                 <th>Title</th>
//                 <th>Category</th>
//                 <th>Date</th>
//                 <th>Action</th>
//               </tr>
//             </thead>



//             {/* tbody  */}
//             {getAllBlog.length > 0
//               ?
//               <>


//                 {getAllBlog.map((item, index) => {
//                   const { thumbnail, date , id } = item;
//                   console.log(item)
//                   return (
//                <tbody>
//                 <tr>

//                 <td>{index + 1}</td>
//                 <td><img src={thumbnail} alt="thumbnail" className="thumbnail" /></td>
//                 <td>  {item.blogs.title}</td>
//                 <td>    {item.blogs.category}</td>
//                 <td>   {date}</td>
//                 <td>
//                   {/* <button className="action-btn">Edit</button> */}
//                   <button className="action-btn" onClick={()=> deleteBlogs(id)}  >Delete</button>
//                 </td>

//                 </tr>
//                </tbody>
//                   )
//                 })}

//               </>
//               :
//               <>
//                 <h1>Loading......</h1>
//               </>
//             }




//           </table>
//         </div>
//       </div>
//     </>

//   );
// };

// export default BlogForm;






































import React, { useContext, useEffect, useState } from 'react';
import './BlogForm.css'; // Import the CSS file
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { fireDb, storage } from '../firebase';
import { toast } from 'react-toastify';
import DataContext from '../Context/DataContext';



const BlogForm = () => {

  const [blogs, setBlogs] = useState({
    title: '',
    category: '',
    content: '',
    time: Timestamp.now(),
  });

  //   const [image, setImage] = useState(null);
  const [thumbnail, setthumbnail] = useState();
  const [imagePreview, setImagePreview] = useState(null);

  // table code 
  // const [getAllBlog, setGetAllBlog] = useState([]);
  const { getAllBlog  , deleteBlogs  } = useContext(DataContext);


//form
  const addPost = async (e) => {
    e.preventDefault();
    if (blogs.title === "" || blogs.category === "" || blogs.content === "" || blogs.thumbnail === "") {
      toast.error('Please Fill All Fields');
    }
    // console.log(blogs.content)
    uploadImage()

    setBlogs({
      title: '',
      category: '',
      content: '',
      time: Timestamp.now(), // Reset time if needed
    });

    // Reset the thumbnail and image preview
    setthumbnail(null);
    setImagePreview(null);

    // You may also reset the file input element if necessary
    e.target.reset();

    
  }

  const uploadImage = () => {
    if (!thumbnail) return;
    const imageRef = ref(storage, `blogimage/${thumbnail.name}`);
    uploadBytes(imageRef, thumbnail).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const productRef = collection(fireDb, "blogPost")
        try {
          addDoc(productRef, {
            blogs,
            thumbnail: url,
            time: Timestamp.now(),
            date: new Date().toLocaleString(
              "en-US",
              {
                month: "short",
                day: "2-digit",
                year: "numeric",
              }
            )
          })
          alert('Data Added Successfully');



        } catch (error) {
          toast.error(error)
          console.log(error)
        }
      });
    });
  }


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setthumbnail(file);
    setImagePreview(URL.createObjectURL(file));
  };


  return (

    <>


      <form onSubmit={addPost} className="blog-form">
        <div className="form-group">
          <label>Title:</label>
          <input
            type="text"
            onChange={(e) => setBlogs({ ...blogs, title: e.target.value })}
            value={blogs.title}
            required
            className='inputtag'
          />
        </div>

        <div className="form-group">
          <label>Category:</label>
          <input
            type="text"
            onChange={(e) => setBlogs({ ...blogs, category: e.target.value })}
            value={blogs.category}
            required
                      className='inputtag'
          />
        </div>

        <div className="form-group">
          <label>Description:</label>
          <textarea
            onChange={(e) => setBlogs({ ...blogs, content: e.target.value })}
            value={blogs.contenty}
            rows="4"
            required
          ></textarea>
        </div>

        <div className="form-group">
          <label>Image:</label>
          <input
            type="file"
            onChange={handleImageChange}
            required
                      className='inputtag'
          />
        </div>

        {imagePreview && (
          <div className="image-preview">
            <img src={imagePreview} alt="Selected" />
          </div>
        )}

        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>

      {/* table */}

      <div className="posts-page">
        <div className="header">
          <h1>Posts</h1>
          <div className="header-actions">
            {/* <button className="add-post-btn" onClick={handleAddPost}>Add New Post</button> */}
          </div>
        </div>
        <div className="table-container">
          <table className="posts-table">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Thumbnail</th>
                <th>Title</th>
                <th>Category</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>



            {/* tbody  */}
            {getAllBlog.length > 0
              ?
              <>


                {getAllBlog.map((item, index) => {
                  const { thumbnail, date , id } = item;
                  console.log(item)
                  return (
               <tbody>
                <tr>

                <td>{index + 1}</td>
                <td><img src={thumbnail} alt="thumbnail" className="thumbnail" /></td>
                <td>  {item.blogs.title}</td>
                <td>    {item.blogs.category}</td>
                <td>   {date}</td>
                <td>
                  {/* <button className="action-btn">Edit</button> */}
                  <button className="action-btn" onClick={()=> deleteBlogs(id)}  >Delete</button>
                </td>

                </tr>
               </tbody>
                  )
                })}

              </>
              :
              <>
                <h1>Loading......</h1>
              </>
            }
          </table>
        </div>
      </div>
    </>

  );
};

export default BlogForm;
