import React, { useContext, useState, useEffect } from 'react';
import './ProductPage.css';
import Footer from '../components/Footer';
import DataContext from '../Context/DataContext';
import { useLocation, useNavigate } from 'react-router-dom';

const ProductPage = () => {
  const { getAllBlog } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const categoryParam = queryParams.get('category') || '';

  // State for filters and loading
  const [category, setCategory] = useState(categoryParam);
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 18;

  useEffect(() => {
    setCategory(categoryParam);
  }, [categoryParam]);

  useEffect(() => {
    // Simulate loading data
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust timeout duration if needed

    return () => clearTimeout(timeout);
  }, [getAllBlog]);

  const handleCategoryChange = (e) => setCategory(e.target.value);
  const handleMinPriceChange = (e) => setMinPrice(e.target.value);
  const handleMaxPriceChange = (e) => setMaxPrice(e.target.value);
  const handleSearchChange = (e) => setSearchQuery(e.target.value);

  const filteredBlogs = getAllBlog.filter((item) => {
    const matchesCategory = category === '' || item.blogs.category === category;
    const matchesSearchQuery =
      searchQuery === '' ||
      item.blogs.title.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesMinPrice = minPrice === '' || item.price >= parseFloat(minPrice);
    const matchesMaxPrice = maxPrice === '' || item.price <= parseFloat(maxPrice);

    return matchesCategory && matchesSearchQuery && matchesMinPrice && matchesMaxPrice;
  });

  // Pagination calculations
  const totalPages = Math.ceil(filteredBlogs.length / itemsPerPage);
  const currentBlogs = filteredBlogs.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="product-page">
        <div className="filters-container">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="search-bar"
          />
          <select value={category} onChange={handleCategoryChange} className="category-filter">
            <option value="">All Categories</option>
            {Array.from(new Set(getAllBlog.map((item) => item.blogs.category))).map((data, index) => (
              <option key={index} value={data}>
                {data}
              </option>
            ))}
          </select>
          {/* <div className="price-range">
            <input
              type="number"
              placeholder="Min Price"
              value={minPrice}
              onChange={handleMinPriceChange}
              className="price-input"
            />
            <input
              type="number"
              placeholder="Max Price"
              value={maxPrice}
              onChange={handleMaxPriceChange}
              className="price-input"
            />
          </div> */}
        </div>

        <div className="product-grid">
          {loading ? (
            <div className="loading-spinner"></div>
          ) : currentBlogs.length > 0 ? (
            currentBlogs.map((item, index) => {
              const { thumbnail, date, id } = item;
              return (
                <div className="card" key={index}>
                  <div className="card-image-container">
                    <img onClick={() => navigate(`/bloginfo/${id}`)} src={thumbnail} alt="img" className="card-image" />
                  </div>
                  <div className="card-content">
                    <h3 className="card-title">{item.blogs.title}</h3>
                    <p className="card-description">{item.blogs.content}</p>
                    {/* <p className="card-category">{item.blogs.category}</p> */}
                    <p className="card-date">{new Date(date).toLocaleDateString()}</p>

                  </div>
                 
                </div>
              );
            })
          ) : (
            <div className="no-data-message">No data found</div>
          )}
        </div>

        {/* Pagination Controls */}
        <div className="pagination-controls">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="pagination-button"
          >
            Previous
          </button>
          <span className="pagination-info">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="pagination-button"
          >
            Next
          </button>
  
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductPage;
