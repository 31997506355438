import React, { useContext } from 'react';
import './ProductSection.css';
import DataContext from '../Context/DataContext';
import { useNavigate } from 'react-router-dom';

const ProductSection = () => {
  const { getAllBlog } = useContext(DataContext);
  const navigate = useNavigate();

  // Sort the blogs by date in descending order (latest first)
  const sortedBlogs = [...getAllBlog].sort((a, b) => new Date(b.date) - new Date(a.date));

  // Limit the number of cards to 10
  const displayedBlogs = sortedBlogs.slice(0, 10);

  return (
    <>
      <div className="product-section-container">
        <h1 id='lastpro' >  LATEST PRODUCTS</h1>
        <div className="product-card-grid">
          {displayedBlogs.length > 0 ? (
            displayedBlogs.map((item) => {
              const { thumbnail, date, id } = item;

              return (



  
  
                <div key={id} className="product-card">
                  <div className="product-card-image-wrapper">
                    <img
                      onClick={() => navigate(`/bloginfo/${id}`)}
                      src={thumbnail}
                      alt="Blog Thumbnail"
                      className="product-card-image"
                    />
                  </div>
                  <div className="product-card-content">
                    <h3 className="product-card-title">{item.blogs.title}</h3>
                    <p className="product-card-description">{item.blogs.content}</p>
                    <p className="product-card-date">{new Date(date).toLocaleDateString()}</p>
                  </div>
                </div>





              );
            })
          ) : (
            <div className="loading-spinner"></div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductSection;
