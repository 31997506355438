// src/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => (
  <footer className="footer">
    <div className="footer-container">
      {/* About Section */}
      <div className="footer-section about">
        <h3>About Us</h3>
        <p>
        At Gift World, we make every occasion special with our curated selection of unique and thoughtful gifts. Discover the perfect present and create unforgettable moments!
        </p>
      </div>

      {/* Contact Information */}
      <div className="footer-section contact">
  <h3>Contact Us</h3>
  <div >
    <i class="fas fa-phone footer-icon"></i>
    <span>+91 9833456000</span>
  </div>
  <div >
    <i class="fas fa-envelope footer-icon"></i>
    <span>giftoworld094@gmail.com</span>
  </div>
</div>


      {/* Social Media */}
      <div className="footer-section social">
        <h3>Follow Us</h3>
        <div className="social-icons">
  <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
    <img src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg" alt="Facebook" className="social-icon" />
  </a>

  <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
    <img src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png" alt="Instagram" className="social-icon" />
  </a>
  <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
    <img src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png" alt="LinkedIn" className="social-icon" />
  </a>
  <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
    <img src="https://upload.wikimedia.org/wikipedia/commons/4/42/YouTube_icon_%282013-2017%29.png" alt="YouTube" className="social-icon" />
  </a>
</div>


      </div>

      {/* Newsletter */}
      <div className="footer-section newsletter">
        <h3>Newsletter</h3>
        <p>Enter your email to subscribe to our newsletter:</p>
        <form className="newsletter-form">
          <input type="email" placeholder="Your email" className="newsletter-input" />
          <button type="submit" className="newsletter-button">Subscribe</button>
        </form>
      </div>
    </div>
    <div className="footer-bottom">
      <p>&copy; {new Date().getFullYear()} Giftoworld. All rights reserved.</p>
    </div>
  </footer>
);

export default Footer;
