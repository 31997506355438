import React from 'react';
import './AboutPage.css';
import Footer from '../components/Footer';
import wakecup from '../img/wakecup.jpeg'
import toteslide from '../img/Tote-slide-banner.jpg'

const AboutPage = () => {
  return (
    <>
      <div className="about-page">
        <section className="about-intro">
          <div className="intro-text">
            <h1>About Us</h1>
            <p>Welcome to GIFTO WORLD, where we offer the finest selection of gifts for every occasion. Our mission is to make gift-giving a delightful experience with our curated collection and exceptional customer service.</p>
          </div>
          <div className="intro-image">
            <img src={wakecup} alt="About Us" />
          </div>
        </section>


        <section class="section_all bg-light" id="about">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section_title_all text-center">
                            <h3 class="font-weight-bold">Welcome To <span class="text-custom">GIFTO WORLD</span></h3>
                            <p class="section_subtitle mx-auto text-muted">At GIFTO WORLD, we believe in the joy of giving. Explore our curated selection of unique and thoughtful gifts designed to bring smiles to every occasion. <br/>Join us in celebrating the art of gifting with exceptional quality and personalized service.</p>
                            <div class="">
                                <i class=""></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row vertical_content_manage mt-5">
                    <div class="col-lg-6">
                        <div class="about_header_main mt-3">
                            <div class="about_icon_box">
                                <p class="text_custom font-weight-bold">The Art of Perfect Gifting</p>
                            </div>
                            <h4 class="about_heading text-capitalize font-weight-bold mt-4">Elevate Every Occasion with Thoughtful Gifts</h4>
                            <p class="text-muted mt-3">Transform ordinary moments into extraordinary memories with our thoughtfully selected gifts. Whether it's a celebration or a simple gesture, find something that truly speaks to the heart.</p>

                            <p class="text-muted mt-3">Celebrate life’s special moments with gifts that leave a lasting impression. Explore our diverse selection and find the ideal way to express your care and appreciation.</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="img_about mt-3">
                            <img src="https://i.ibb.co/qpz1hvM/About-us.jpg" alt="" class="img-fluid mx-auto d-block" />
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-lg-4">
                        <div class="about_content_box_all mt-3">
                            <div class="about_detail text-center">
                                <div class="about_icon">
                                    <i class="fas fa-pencil-alt"></i>
                                </div>
                                <h5 class="text-dark text-capitalize mt-3 font-weight-bold">Creative Design for Memorable Gifts</h5>
                                <p class="edu_desc mt-3 mb-0 text-muted">Discover the artistry behind our unique gifts, where creative design meets heartfelt sentiment. Our curated selection brings innovation and elegance to every occasion. </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="about_content_box_all mt-3">
                            <div class="about_detail text-center">
                                <div class="about_icon">
                                    <i class="fab fa-angellist"></i>
                                </div>
                                <h5 class="text-dark text-capitalize mt-3 font-weight-bold">Your Satisfaction, Our Priority</h5>
                                <p class="edu_desc mb-0 mt-3 text-muted">We are committed to achieving the best outcomes by combining innovative design with superior quality, making sure every gift meets and exceeds your expectations.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="about_content_box_all mt-3">
                            <div class="about_detail text-center">
                                <div class="about_icon">
                                    <i class="fas fa-paper-plane"></i>
                                </div>
                                <h5 class="text-dark text-capitalize mt-3 font-weight-bold">Your Ultimate Destination</h5>
                                <p class="edu_desc mb-0 mt-3 text-muted">Discover the premier platform for all your gifting needs, where top-quality products and exceptional service come together seamlessly. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className="company-history">
          <h2>Our History</h2>
          <p>GIFTN WORLD started with the goal of revolutionizing the gift industry. Over the years, we have expanded our product range and built a loyal customer base. Our commitment to quality and service remains at the core of everything we do.</p>
          <img src={toteslide} alt="Company History" />
        </section>
      </div>

      <Footer />
    </>
  );
};

export default AboutPage;
