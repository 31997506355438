import React from 'react';
import './ContactPage.css'; // Import CSS for styling
import Footer from '../components/Footer';
const ContactPage = () => {
  return (
    <>
    <div className="contact-page">
      <header className="contact-header">
        <h1>Contact Us</h1>
        <p>We'd love to hear from you! Feel free to reach out through any of the methods below.</p>
      </header>

      <section className="contact-info">
        <div className="contact-details">
          <h2>Contact Details</h2>
          <div className="contact-item">
            <i className="fas fa-map-marker-alt"></i>
            <div className="contact-text">
              <h3>Our Address</h3>
              <p>A14 giriraj industrial estate <br />mahakali rode, andheri east</p>
            </div>
          </div>
          <div className="contact-item">
            <i className="fas fa-phone-alt"></i>
            <div className="contact-text">
              <h3>Phone Number</h3>
              <p>+91 9833456000</p>
            </div>
          </div>
          <div className="contact-item">
            <i className="fas fa-envelope"></i>
            <div className="contact-text">
              <h3>Email Address</h3>
              <p>giftover94@gmail.com</p>
            </div>
          </div>
        </div>

        <div className="contact-form">
          <h2>Send Us a Message</h2>
          <form>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" rows="5" required></textarea>
            </div>
            <button type="submit" className="submit-button-btn">Send Message</button>
          </form>
        </div>
      </section>

      <section className="contact-map">
        <h2>Find Us on the Map</h2>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.436936942184!2d-122.41941848468126!3d37.77492927975982!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085808c3f8c1f47%3A0x37c9cba96b2736eb!2s1234%20Gift%20Ave%2C%20San%20Francisco%2C%20CA%2094111!5e0!3m2!1sen!2sus!4v1609459200000!5m2!1sen!2sus"
          title="Google Maps"
          allowFullScreen
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </section>
    </div>
<Footer/>

    </>
  );
};

export default ContactPage;
