// src/FeaturesSection.js
import React from 'react';
import './FeaturesSection.css';

const FeaturesSection = () => (
    <>
    <br />
  <section className="features-section">

    <div className="features-container">
      <div className="feature">
        <i className="feature-icon fas fa-truck"></i>
        <h3 className="feature-title">Fast Delivery</h3>
        <p className="feature-description">Get your products quickly with our efficient delivery service.</p>
      </div>
      <div className="feature">
        <i className="feature-icon fas fa-star"></i>
        <h3 className="feature-title">Best Quality</h3>
        <p className="feature-description">We offer top-notch products that meet high-quality standards.</p>
      </div>
      <div className="feature">
        <i className="feature-icon fas fa-shipping-fast"></i>
        <h3 className="feature-title">shipping</h3>
        <p className="feature-description">Enjoy  shipping on all orders with no minimum purchase.</p>
      </div>
      <div className="feature">
        <i className="feature-icon fas fa-headset"></i>
        <h3 className="feature-title">24/7 Customer Service</h3>
        <p className="feature-description">Our customer service team is here to assist you around the clock.</p>
      </div>
    </div>
  </section>
  </>
);

export default FeaturesSection;
