import React from 'react'
import Slider from '../components/Slider.jsx'
import AboutSection from '../components/AboutSection.jsx';
import FeaturesSection from '../components/FeaturesSection.jsx';
import Footer from '../components/Footer.jsx';
import humidifiler  from '../img/Humidifier.jpeg'
import speaker from  '../img/speker.jpeg'
import clock from '../img/Clock.jpeg'
import giftbox from '../img/Giftbox.jpeg'
import Herosection from '../herosections/HeroSection.jsx'
import WelcomeSection from '../herosections/WelcomeSection.jsx';
import ProductSection from '../components/ProductSection.jsx';
// import Book from '../herosections/Book.jsx';
import HorizontalScrollCards from '../herosections/HorizontalScrollCards.jsx';
import CategoryGrid from '../herosections/CategoryGrid.jsx';





// Sample product data
// const mostSoldProducts = [

//   { image: humidifiler , title: 'Air Purifier And Humidifier', },
//   { image: speaker, title: 'Gf2315 Wireless Speaker' },
//   { image: clock, title: ' Alarm Clock Classic Retro ' },
//   { image: giftbox, title: 'All White Exclusive Gift Box' },


// ];

// const mostPopularProducts = [
//   { image: 'https://via.placeholder.com/300x200?text=Gift+4', title: 'Gift Item 4', price: '$24.99' },
//   { image: 'https://via.placeholder.com/300x200?text=Gift+5', title: 'Gift Item 5', price: '$34.99' },
//   { image: 'https://via.placeholder.com/300x200?text=Gift+6', title: 'Gift Item 6', price: '$44.99' },
// ];

// const latestProducts = [
//   { image: 'https://via.placeholder.com/300x200?text=Gift+7', title: 'Gift Item 7', price: '$14.99' },
//   { image: 'https://via.placeholder.com/300x200?text=Gift+8', title: 'Gift Item 8', price: '$25.99' },
//   { image: 'https://via.placeholder.com/300x200?text=Gift+9', title: 'Gift Item 9', price: '$35.99' },
// ];



function  Home() {
  return (
<>
<Slider />

<FeaturesSection/>
<CategoryGrid/>

<ProductSection/>
{/* <Book /> */}
<HorizontalScrollCards/>
<Herosection/>
<AboutSection />

<WelcomeSection/>
{/* <ProductSection title="Latest Products" products={mostSoldProducts} /> */}
{/* <ProductSection title="Most Popular Gifts" products={mostPopularProducts} /> */}
{/* <ProductSection title="Latest Gifts" products={latestProducts} /> */}
<Footer/>

</>
  )
}

export default Home
