// DataContext.js (or AppContext.js)
import React, { createContext, useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { fireDb } from '../firebase'; // Update with your Firebase config path

const DataContext = createContext();

export function DataProvider({ children }) {
  const [getAllBlog, setGetAllBlog] = useState([]);

  useEffect(() => {
    function getAllBlogs() {
      try {
        const q = query(
          collection(fireDb, 'blogPost'),
          orderBy('time')
        );
        const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
          let blogArray = [];
          QuerySnapshot.forEach((doc) => {
            blogArray.push({ ...doc.data(), id: doc.id });
          });
          setGetAllBlog(blogArray);
        });
        return () => unsubscribe();
      } catch (error) {
        console.error(error);
      }
    }

    getAllBlogs();
  }, []);




  // Blog Delete Function 
  const deleteBlogs = async (id) => {
    try {
        await deleteDoc(doc(fireDb, "blogPost", id));
        getAllBlog();
        alert("Blogs deleted successfully");
    } catch (error) {
        console.log(error)
    }
}


  return (
    <DataContext.Provider value={{ getAllBlog , deleteBlogs  }}>
      {children}
    </DataContext.Provider>
  );
}

export default DataContext;
