import React from 'react';
import './CategoryGrid.css';
import bags from '../img/bags.jpg'
import Infuseur from '../img/Infuseur.jpeg'
import mug from '../img/coffemug.jpg'
import Keychain from '../img/Keychain.jpeg'
import Mousepad from '../img/Mousepad.jpg'
import Namebadges from '../img/Namebadges.jpeg'
import photoframes from '../img/photoframes.jpeg'
import Pillow from '../img/Pillow.jpg'
import tshirt from '../img/t-shirt.jpeg'
import Coaster from '../img/Coaster.jpeg'

const categories = [
  { name: 'Bags', img:  bags  },
  { name: 'Bottles & Sippers', img: Infuseur },
  { name: 'Coffee Mugs', img: mug   },
  { name: 'Keychains', img:  Keychain  },
  { name: 'Name Badges', img: Namebadges   },
  { name: 'Mousepads', img: Mousepad   },
  { name: 'Photo Frames', img: photoframes   },
  { name: 'Pillow Covers', img:  Pillow  },
  { name: 'T-Shirts', img: tshirt  },
  { name: 'Tea Coasters', img: Coaster  },
  
];

const CategoryGrid = () => {
  return (
    <div className="category-grid">
      {categories.map((category, index) => (
        <div className="category-item" key={index}>
          <img src={category.img} alt={category.name} className="category-img" />
          <div className="category-text">{category.name}</div>
        </div>
      ))}
    </div>
  );
};

export default CategoryGrid;
